import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Homepage from "../HomePage/HomePage";
// import HomePage2 from "../HomePage2/HomePage2";
import AboutPage from "../AboutPage/AboutPage";
// import ServicePage from "../ServicePage/ServicePage";
// import ServiceSinglePage from "../ServiceSinglePage/ServiceSinglePage";
import ProjectPage from "../ProjectPage/ProjectPage";
// import ProjectSinglePage from "../ProjectSinglePage/ProjectSinglePage";
import BlogPage from "../BlogPage/BlogPage";
import BlogDetails from "../BlogDetails/BlogDetails";
import ContactPage from "../ContactPage/ContactPage";
import SilverVilla from "../ProjectPage/SilverVilla";
import GoldenVilla from "../ProjectPage/GoldenVilla";
import DiamondVilla from "../ProjectPage/DiamondVilla";
import Project from "../project/Project";
import ProjectDetails from "../project/ProjectDetails";
import "bootstrap/dist/css/bootstrap.min.css";
import DefaultMeta from "../../components/DefaultMeta";

const AllRoute = () => {
  return (
    <div className="App">
      <DefaultMeta />
      <BrowserRouter>
        <Routes>
        
          <Route path="/" element={<Homepage />} />
          <Route path="home" element={<Homepage />} />
          {/* <Route path="home2" element={<HomePage2 />} /> */}
          <Route path="about" element={<AboutPage />} />
          <Route path="victoryvillas" element={<ProjectPage />} />
          <Route path="silvervillas" element={<SilverVilla />} />
          <Route path="goldenvillas" element={<GoldenVilla />} />
          {/* <Route path="diamondvillas" element={<DiamondVilla />} /> */}

          {/* <Route path="project-single/:slug" element={<ProjectSinglePage />} />
          <Route path="services" element={<ServicePage />} />
          <Route path="service-single/:slug" element={<ServiceSinglePage />} />*/}
          <Route path="blog" element={<BlogPage />} />
          <Route path="blog/:slug" element={<BlogDetails />} />
          <Route path="contact" element={<ContactPage />} />
          {/* <Route path="projects" element={<Project />} />
          <Route path="/projects/:slug" element={<ProjectDetails />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
