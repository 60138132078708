import React from "react";
import abimg1 from "../../images/MapofVV.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const Location = () => {
  return (
    <>
      <section className=" ">
        <div className="container">
          <div className="row">
            <div className="section-title m-20">
              <h2 className="text-center mb-50">
                Our <span className="thin">Strategic Location</span>
              </h2>
            </div>

            <div className="col-lg-6">
              <div className="wp-content about-content mb-10 ml-50 rmb-50">
                <div className="section-title ">
                  <h6>Explore </h6>

                  <h6>our strategic location</h6>

                  <ul className="styled-list">
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      Within Airport Green Gardens
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      5 minute from Islamabad International Airport
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      7 minute drive from Islamabad Highway
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      5 minute drive from Motorway M1/M2
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      5 minute drive from GT Road
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="">
                {/* <div className="experience-year">
                  <span className="number">
                    <FaCheck size={60} />
                  </span>
                  <h4>Modern architecture</h4>
                </div> */}
                <img src={abimg1} alt="Wordpress" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Location;
