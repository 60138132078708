import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { createClient } from "contentful";
import Navbar from "../../components/Navbar/Navbar";
import VillasProjectDetails from "../../components/project/projectDetails/VillasProjectDetails";
import Footer from "../../components/footer/Footer";

import VillasBanner from "../../components/project/projectDetails/VillasBanner";

const client = createClient({
  space: "qyzgwyj54129",
  accessToken: "Ds5IGXCqFaJgJURr-OiVw60qGdjd2hcvXlF1aG4rsy8",
});

const ProjectDetails = () => {
  const { slug } = useParams();
  const [villa, setVilla] = useState(null);

  useEffect(() => {
    async function fetchVilla() {
      try {
        const entry = await client.getEntries({
          content_type: "villas",
          "fields.slug": slug,
        });

        if (entry.items.length > 0) {
          setVilla(entry.items[0].fields);
        }
      } catch (error) {
        console.error("Error fetching villa details:", error);
      }
    }

    fetchVilla();
  }, [slug]);

  return (
    <>
      <Navbar />

      <VillasBanner villa={villa} />
      <VillasProjectDetails villa={villa} />

      <Footer />
    </>
  );
};

export default ProjectDetails;
