import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const LifeInVv = () => {
  return (
    <>
      <section className=" ">
        <div className="container">
          <div className="row">
            <div className="section-title m-20">
              <h2 className="text-center mb-50">
                Life In <span className="thin">Airport Green Gardens</span>
              </h2>
            </div>

            <div className="col-lg-4">
              <div className="wp-content about-content mb-10 ml-50 rmb-50">
                <div className="section-title ">
                  <ul className="styled-list">
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      Uninterrupted Gas Supply
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      24/7 Electricity
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      Water Supply
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      Broadband Internet Services
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      Water Drainage
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      Sports Club
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      Yard Streets
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="wp-content about-content mb-10 ml-50 rmb-50">
                <div className="section-title ">
                  <ul className="styled-list">
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      Main Boulevard
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      24/7 Security
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      High tech Hospital
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      Shopping Malls
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      International Standard Schools
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      Mosques
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      Metro Transportation
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="wp-content about-content mb-10 ml-50 rmb-50">
                <div className="section-title ">
                  <ul className="styled-list">
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      Entertainment & Sports
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      Maintainance Facilities
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      Waste Management
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      Solar Pannel Installation
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      Smart Home Features
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      Emergency Quick Response
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LifeInVv;
