import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";

import Footer from "../../components/footer/Footer";
import Table from "../../components/projectsComp/table/Table";
import DownloadPlan from "../../components/projectsComp/DownloadPlan";
import PlanHeading from "../../components/projectsComp/PlanHeading";
import { Helmet } from "react-helmet";
import { metaData } from "../../api/metaData";
import VictoryVillasDis from "../../components/projectsComp/VictoryVillasDis";

import Aboutsilver from "../../components/projectsComp/villasDetails/Aboutsilver.js";
import AboutGolden from "../../components/projectsComp/villasDetails/AboutGolden.js";
import AboutDiamond from "../../components/projectsComp/villasDetails/AboutDiamond.js";
import Artitucture from "../../components/artitucture/Artitucture.js";

const ProjectPage = () => {
  const page = "Projects";
  const { title, description } = metaData(page);
  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Navbar />
      <PageTitle pageTitle={"Victory Villas"} />
      <VictoryVillasDis />

      <Artitucture />
      {/* <ProjectSectionS2 /> */}

      <Aboutsilver />
      <AboutGolden />
      {/* <AboutDiamond /> */}
      <div className=" mt-135">
        <PlanHeading />
      </div>

      <Table />
      <DownloadPlan />

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ProjectPage;
