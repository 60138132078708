import React from "react";
import { Helmet } from "react-helmet";

import BlogForm from "./BlogForm";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
const BlogSingle = ({ blog }) => {
  if (!blog) {
    return <p>Loading...</p>;
  }
  const articleUrl = `https://victoryvillas.pk/blog/${blog.slug}`;
  console.log(blog.slug);

  const shareUrl = encodeURIComponent(articleUrl);

  return (
    <>
    <Helmet>
    <title>{blog.title}</title>
        <meta name="description" content={blog.shortDiscription} />
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={blog.shortDiscription} />
        <meta property="og:image" content={blog.featureImage.fields.file.url} />
        <meta property="og:url" content={articleUrl} /></Helmet>
    <section className="blog-single mt-150 mb-50">
      <div className="container">
        <div className="blog-image">
          {blog.featureImage && (
            <img
              src={"https:" + blog.featureImage.fields.file.url}
              alt={blog.title}
            />
          )}
          <span className="date">{blog.createAt}</span>
        </div>
        <h3 className="blog-title">{blog.title}</h3>
        {/* <ul className="admin-header">
          <li>
            <i className="fa fa-user"></i>By {blog.auther}
          </li>
          <li>
            <i className="fa fa-graduation-cap"></i>Property
          </li>
          <li>
            <i className="fa fa-home"></i>Residence
          </li>
        </ul> */}

        <p>{blog.discription && documentToReactComponents(blog.discription)}</p>

        <div className="row mt-65">
          {blog.gallary.map((image, index) => (
            <div className="col-md-6 mb-40" key={index}>
              <img
                src={"https:" + image.fields.file.url}
                alt={`Gallery ${index + 1}`}
              />
            </div>
          ))}
        </div>
        {/* <div className="blog-share mb-65">
          <div className="social-icons">
            <span className="social-title">Share</span>
            <span>
              <a
                href={`https://twitter.com/intent/tweet?url=${shareUrl}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-twitter"></i>
              </a>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-facebook-f"></i>
              </a>
              <a
                href={`https://www.instagram.com/share?url=${shareUrl}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-instagram"></i>
              </a>
              <a
                href={`https://www.linkedin.com/shareArticle?url=${shareUrl}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-linkedin"></i>
              </a>
            </span>
          </div>
        </div> */}
        <form className="comment-form">
          <h3 className="comment-title">Leave a Reply</h3>
          <BlogForm />
        </form>
      </div>
    </section>
    </>
  );
};

export default BlogSingle;
