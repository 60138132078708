import React, { Fragment } from "react";
import PageTitle from "../../components/pagetitle/PageTitle";
import BlogList from "../../components/BlogList/BlogList.js";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";

const BlogPage = () => {
  return (
    <Fragment>
      <Navbar hclass={"header-style-2"} />
      <PageTitle pageTitle={"Our Blogs"} pagesub={""} />
      <BlogList />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default BlogPage;
