import React, { useEffect, useState } from "react";
import { createClient } from "contentful";

import { Link } from "react-router-dom";

const client = createClient({
  space: "qyzgwyj54129",
  accessToken: "Ds5IGXCqFaJgJURr-OiVw60qGdjd2hcvXlF1aG4rsy8",
});

function LatestBlog() {
  const [blog, setBlog] = useState([]);

  useEffect(() => {
    async function fetchBlog() {
      try {
        const entries = await client.getEntries({
          content_type: "blog",
        });
        setBlog(entries.items);
      } catch (error) {
        console.error("Error fetching entries:", error);
      }
    }

    fetchBlog();
  }, []);

  const truncateText = (text, limit) => {
    const words = text.split(" ");
    return words.length > limit
      ? words.slice(0, limit).join(" ") + "..."
      : text;
  };

  return (
    <>
      <section className={`services-section rpt-100 pb-70 pt-70 `}>
        <div className="container">
          <div className="d-flex flex-column justify-content-center align-items-center mb-75">
            <div className="section-title text-center">
              <h2>
                Our <span className="thin">Latest Blogs</span>
              </h2>
            </div>
            {/* <p className="text-center col-lg-11 col-sm-12">
              Read Our Latest Blogs
            </p> */}
          </div>
          <div className="row">
            {blog.slice(0, 3).map((blog) => {
              const { title, slug, shortDiscription, thumbnail } = blog.fields;
              return (
                <div className="col-lg-4 col-md-6" key={slug}>
                  <Link to={"/blog/" + slug} className="text-black">
                    <div className="team-item">
                      <img
                        src={"https:" + thumbnail?.fields?.file?.url}
                        alt="iconImage"
                      />
                      <div className="team-des">
                        <h3>{title}</h3>

                        <p>{truncateText(shortDiscription, 15)}</p>
                        <Link to={"/blog/" + slug} className="text-black">
                          <p>Read More</p>
                        </Link>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default LatestBlog;
