// src/components/DefaultMeta.js
import React from "react";
import { Helmet } from "react-helmet";

const DefaultMeta = () => (
  <Helmet>
    <meta name="description" content="Web site created using create-react-app" />
    <meta property="og:description" content="Explore the finest properties and find your dream home with Victory Villas." />
    <meta property="og:image" content="%PUBLIC_URL%/images/og-image.jpg" />
    <meta property="og:url" content="https://victoryvillas.pk/" />
  </Helmet>
);

export default DefaultMeta;
