import simg from "../images/services/icon1.png";
import simg2 from "../images/services/icon2.png";
import simg3 from "../images/services/icon3.png";
import simg4 from "../images/services/icon4.png";
import simg5 from "../images/services/icon5.png";
import simg6 from "../images/services/icon6.png";

import sSimg1 from "../images/service-single/img-1.jpg";
import sSimg2 from "../images/service-single/img-2.jpg";
import sSimg3 from "../images/service-single/img-3.jpg";
import sSimg4 from "../images/service-single/img-1.jpg";
import sSimg5 from "../images/service-single/img-2.jpg";
import sSimg6 from "../images/service-single/img-3.jpg";

import sSingleimg1 from "../images/service-single/2.jpg";
import sSingleimg2 from "../images/service-single/3.jpg";

const Services = [
  {
    Id: "1",
    sImg: simg,
    sSimg: sSimg1,
    sTitle: "Design & Planning",
    slug: "Design-&-Planning",
    description:
      "Thoughtful layouts, exceptional architecture for a timeless living experience	",
    des2: "Elementum aliquam byuerirra sagittis duis non quis",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "2",
    sImg: simg2,
    sSimg: sSimg2,
    sTitle: "Custom Solutions",
    slug: "Custom-Solutions",
    description:
      "Tailor your dream home. We craft spaces that reflect your unique style",
    des2: "Elementum aliquam byuerirra sagittis duis non quis",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "3",
    sImg: simg3,
    sSimg: sSimg3,
    sTitle: "Furniture & Decor",
    slug: "Furniture-&-Decor",
    description:
      "Elevate your space. Curated selections to complement your luxurious lifestyle",
    des2: "Elementum aliquam byuerirra sagittis duis non quis",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "4",
    sImg: simg4,
    sSimg: sSimg4,
    sTitle: "Exterior Design",
    slug: "Exterior-Design",
    description:
      "Noimy donecing and vivamus vitae commodo. Ut purus ornare laoreet ultrices are sitac",
    des2: "Elementum aliquam byuerirra sagittis duis non quis.",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "5",
    sImg: simg5,
    sSimg: sSimg5,
    sTitle: "Interior Design",
    slug: "Interior-Design",
    description:
      "Noimy donecing and vivamus vitae commodo. Ut purus ornare laoreet ultrices are sitac",
    des2: "Elementum aliquam byuerirra sagittis duis non quis.",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "6",
    sImg: simg6,
    sSimg: sSimg6,
    sTitle: "Concept Planning",
    slug: "Concept-Planning",
    description:
      "Noimy donecing and vivamus vitae commodo. Ut purus ornare laoreet ultrices are sitac",
    des2: "Elementum aliquam byuerirra sagittis duis non quis.",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
];
export default Services;
