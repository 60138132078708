import React from "react";
import abimg1 from "../../images/Home-image2.jpg";

import nocImg from "../../images/NOC.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Location from "./Location";

const WhyVictory = () => {
  return (
    <>
      <section className="">
        <div className="container">
          <div className="row">
            <div className="section-title m-20">
              <h2 className="text-center mb-50">
                Why
                <span className="thin"> Victory Villas?</span>
              </h2>
            </div>
            <div className="col-lg-6">
              <div className="wp-images">
                {/* <div className="experience-year">
                  <span className="number">
                    <FaCheck size={60} />
                  </span>
                  <h4>Modern architecture</h4>
                </div> */}
                <img src={abimg1} alt="Wordpress" />
                <img
                  style={{
                    width: "180px",
                    opacity: "0",
                  }}
                  src={nocImg}
                  alt="Wordpress"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="wp-content about-content mb-10 ml-50 rmb-50">
                <div className="section-title ">
                  <h6>Our </h6>

                  <h6>features & amenities</h6>

                  <ul className="styled-list">
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      NOC approved by RDA
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      Home Automation
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      Stunning Architecture
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      High End Security System
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      Spacious Layouts
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      Private Outdoor Spaces
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      Smart Home Technology
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      Fitness and wellness facilities
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      Home theatre & entertainment spaces
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      Gourmet kitchen
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <Location />
        </div>
      </section>
    </>
  );
};

export default WhyVictory;
