import React from "react";

import abimg1 from "../../../images/MidTown/01.png";
import abimg2 from "../../../images/MidTown/02.png";
import nocImg from "../../../images/NOC.png";
import Ongoing from "../../../api/Ongoing";
import Heading from "./Heading";
import GreenGardan from "./greenGardan/GreenGardan";
const OngoingProjects = () => {
  return (
    <>
      <section
        id="about"
        className="wp-section pt-150 rmb-100 mb-100"
        // style={{ backgroundImage: `url(${Bg})` }}
      >
        <Heading />
        <GreenGardan />
        <div className="container">
          <div className="row align-items-center">
            <div className="section-title mb-10">
              <h2 className="text-center ">
                Midtown Jhelum
                <span className="thin">Society:</span>
              </h2>

              <p className="text-center">
                Residental Villas (5 and 10 marlas). Located on the main GT
                Road, Jhelum, near Punjab University
              </p>
            </div>

            <div className="col-lg-6">
              <div className="wp-images rmb-150">
                {/* <div className="experience-year">
                  <span className="number">
                    <img src={nocImg} alt="noc approved" />
                  </span>
                  <h4>Years of Experience</h4>
                </div> */}
                <img src={abimg2} alt="Wordpress" />
                <img
                  style={{
                    width: "180px",
                  }}
                  src={nocImg}
                  alt="Wordpress"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="rml-0 rpt-30">
                <div className="row">
                  {Ongoing.map((villa, Sitem) => (
                    <div className="col-lg-6 col-md-6 col-12" key={Sitem}>
                      <div className="service-item">
                        <span className="number">0{villa.Id}</span>
                        <h3>{villa.sTitle}</h3>
                        <p>{villa.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OngoingProjects;
